import React, { useState } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import crossIcon from './../assets/img/cross.png'
import './../assets/css/embauche.css'

import whiteArrow from './../assets/img/white_arrow.svg'
import onlyLogoHome from './../assets/img/only_logo_home.svg'
import subtitleWhite from './../assets/img/subtitle_white_stroke.svg'
import embauchePrincipal from './../assets/img/embauche_principal.svg'
import uploadSolid from './../assets/img/upload-solid.svg'

const Embauche = () => {
  const [dataForm, setDataForm] = useState({
    nom: '',
    nomDeLetablissement: '',
    fonction: '',
    telephone: '',
    email: ''
  })

  const [dataFormErr, setDataFormErr] = useState({
    nom: false,
    nomDeLetablissement: false,
    fonction: false,
    telephone: false,
    email: false,
    file: false
  })

  const [success, setSuccess] = useState(false)
  const [file, setFile] = useState(null)

  const handleChange = e => {
    const { value, name } = e.target
    console.log(dataForm)
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const validateData = () => {
    const { nom, email, nomDeLetablissement, telephone, fonction } = dataForm

    function validateEmail (mail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(mail).toLowerCase())
    }

    setDataFormErr({
      nom: nom.length === 0,
      email: !validateEmail(email),
      nomDeLetablissement: nomDeLetablissement.length === 0,
      telephone: telephone.length === 0,
      fonction: fonction.length === 0,
      file: file === null
    })

    if (nom.length === 0) return false
    else if (!validateEmail(email)) return false
    else if (nomDeLetablissement.length === 0) return false
    else if (telephone.length === 0) return false
    else if (fonction.length === 0) return false
    else if (file === null) return false
    else return true
  }

  const sendMsg = e => {
    const isValid = validateData()
    console.log('send')
    e.preventDefault()

    const { nom, email, nomDeLetablissement, telephone, fonction } = dataForm

    console.log(isValid)

    if (isValid) {
      const formData = new FormData()
      formData.append('nom :', nom)
      formData.append('Nom de L\'établissement :', nomDeLetablissement)
      formData.append('Fonction :', fonction)
      formData.append('email :', email)
      formData.append('téléphone :', telephone)
      formData.append('Cv :', file)

      console.log(file)

      console.log('good')

      return axios.post('https://usebasin.com/f/67145973f0b6', formData,
        { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' } }
      )
        .then(res => {
          console.log('sended', res)
          if (res.data.success) {
            setSuccess(true)
            setDataForm({
              nom: '',
              nomDeLetablissement: '',
              fonction: '',
              telephone: '',
              email: ''
            })
            setFile(null)
            setTimeout(() => setSuccess(false), 5000)
          }
        })
        .catch(err => {
          // console.log("not send", err);
        })
    }
  }

  const handleFileInput = (e) => {
    // handle validations
    const files = e.target.files[0]
    console.log(e.target.files[0])
    if (files) {
      setFile(files)
    }
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files[0]
    console.log(files)
    if (files.type === 'application/pdf' ||
      files.type === 'image/png' ||
      files.type === 'image/jpg' ||
      files.type === 'image/jpeg' ||
      files.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      files.type === 'application/msword' ||
      files.type === 'text/plain') return setFile(files)
  }
  return (
    <>
      <Helmet>
        <title>Embauche | Paramedic</title>
      </Helmet>
      <section id='section_embauche_home'>
        <div className='container-fluid'>
          <div className='row' id='home-principal-row'>
            <div className='col-lg-6 right_home delay_500'>
              <div className='row'>
                <img
                  draggable='false' src={onlyLogoHome} alt='Logo Paramedic Blanc'
                  className='logo_home' data-aos='fade-right' data-aos-duration='1000'
                />
              </div>
              <h1>Rejoindre notre équipe ?</h1>
              <img
                draggable='false' src={subtitleWhite}
                alt='Traits blancs à bouts arrondis' className='subtitle_white_stroke' data-aos='fade-right'
                data-aos-duration='1000' data-aos-delay='500'
              />
              <p data-aos='fade-right' data-aos-duration='1000' data-aos-delay='650'>Vous souhaitez avoir de plus
                amples informations concernant Paramedic ?<br />Un éventuel partenariat ? Rejoindre notre équipe ?
              </p>
              <Link to='#section_embauche_form'>
                <img
                  draggable='false' src={whiteArrow}
                  alt='Flèche blanche orientée vers le bas'
                  className='white_arrow slide-bottom-faster'
                />
              </Link>
            </div>
            <div className='col-lg-6 left_home'>
              <img
                draggable='false' className='slide-bottom delay_100' src={embauchePrincipal}
                alt='Illustration groupe de personnes travaillant sur pc'
              />
            </div>
          </div>
        </div>
      </section>

      <section id='section_embauche_form'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='form-title'>Soumettre votre candidature</h3>
            </div>
          </div>
          <form onSubmit={sendMsg} encType='multipart/form-data'>
            <input type='hidden' name='form-name' value='contact v1' />
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Vous êtes ?*</label>
                  <input value={dataForm.nom} onChange={e => handleChange(e)} type='text' name='nom' id='first-name' className={`form-control ${dataFormErr.nom ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Nom de l’établissement / Société de Transport*</label>
                  <input value={dataForm.nomDeLetablissement} onChange={e => handleChange(e)} type='text' name='nomDeLetablissement' className={`form-control ${dataFormErr.nomDeLetablissement ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Fonction*</label>
                  <input value={dataForm.fonction} onChange={e => handleChange(e)} type='text' name='fonction' className={`form-control ${dataFormErr.fonction ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Téléphone*</label>
                  <input value={dataForm.telephone} onChange={e => handleChange(e)} type='text' name='telephone' className={`form-control ${dataFormErr.telephone ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Adresse éléctronique*</label>
                  <input value={dataForm.email} onChange={e => handleChange(e)} type='email' name='email' className={`form-control ${dataFormErr.email ? 'invalid' : ''}`} />
                </div>
                <div id='upload_zone'>
                  <p>Ajouter votre CV</p>
                  <div
                    className={`drop-zone row ${dataFormErr.file ? 'invalid-file' : ''}`} onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                  >
                    <input
                      onChange={handleFileInput}
                      id='myFile'
                      type='file'
                      name='cv'
                      className='drop-zone__input'
                      accept='image/*,.pdf,.doc,.docx'
                    />
                    <div className='col-md-12'>
                      <img
                        draggable='false' src={uploadSolid} className='upload_icon'
                        alt='icône téléchargement'
                      />
                    </div>
                    <div
                      className='col-md-12'
                    >
                      <span style={{ color: '#2FABE0' }} className='drop-zone__prompt'>
                        Parcourir...
                      </span>
                    </div>
                  </div>
                </div>
                {file && <p>{file.name}
                  <span onClick={() => setFile(null)}>
                    <img style={{ marginLeft: '1em', marginBottom: '0.2em', cursor: 'pointer', width: '1.2em' }} src={crossIcon} alt='paramedic' />
                  </span>
                </p>}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 btn-container'>
                <button type='submit' className='btn-submit'>Soumettre</button>
              </div>
            </div>
            {dataFormErr.checked || dataFormErr.telephone || dataFormErr.nomDeLetablissement || dataFormErr.message || dataFormErr.nom || dataFormErr.email || dataFormErr.fonction || dataFormErr.file
              ? (
                <div style={{ height: '3em', marginTop: '1em' }}>
                  <p className='success' style={{ backgroundColor: 'red' }}>Veuillez remplir les champs indiquer en rouge</p>
                </div>
                )
              : null}

            {success && (
              <div style={{ height: '3em', marginTop: '1em' }}>
                <p className='success'>Votre message a bien été envoyer</p>
              </div>
            )}
          </form>
        </div>
      </section>

    </>
  )
}

export default Embauche
